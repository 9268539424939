<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script></script>

<style>
body {
  margin: 0;
}
</style>
