import { Vector3 } from '@/AR-Renderer/structs/vector3.js';
import { Quaternion } from '@/AR-Renderer/structs/quaternion';

class JSON {
	constructor() {}
	isValid() {}
}

class FaceTransform extends JSON {

	constructor(position, rotation, scale, cameraFOV = 60, faceMeshData = []) {
		super();
		if (!position) {
			position = new Vector3();
		}
		if (!rotation) {
			rotation = new Quaternion();
		}
		if (!scale) {
			scale = new Vector3();
		}

		this.position = position;
		this.rotation = rotation;
		this.scale = scale;
		this.cameraFOV = cameraFOV;
		this.faceMeshData = faceMeshData.join();
	}	

	isValid() {
		if (this.position && this.rotation && this.scale && this.faceMeshData) {
			return true;
		} else {
			return false;
		}
	}
}

class TargetTransform extends JSON {

	constructor(position, rotation, scale, cameraFOV = 60, targetIndex = 0) {
		super();
		if (!position) {
			position = new Vector3();
		}
		if (!rotation) {
			rotation = new Quaternion();
		}
		if (!scale) {
			scale = new Vector3();
		}

		this.position = position;
		this.rotation = rotation;
		this.scale = scale;
		this.cameraFOV = cameraFOV;
		this.targetIndex = targetIndex;
	}

	isValid() {
		if (this.position && this.rotation && this.scale) {
			return true;
		} else {
			return false;
		}
	}
}

export { JSON, FaceTransform, TargetTransform };