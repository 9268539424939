import { createApp } from 'vue'
import App from "./App.vue";
import * as VueRouter from 'vue-router'

import Home from "./views/Home.vue";

const router = VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    routes: [
        {
            path: "/:id?",
            component: Home,
        },
    ],
})

createApp(App).use(router).mount("#app");