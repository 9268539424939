class GeolocationCoordinates {

	constructor(accuracy = -1, altitude = -1, heading = -1, latitude = -1, longitude = -1, speed = -1) {
		this.accuracy = accuracy;
		this.altitude = altitude;
		this.heading = heading;
		this.latitude = latitude;
		this.longitude = longitude;
		this.speed = speed;
	}

	set(accuracy, altitude, heading, latitude, longitude, speed) {
		this.accuracy = accuracy;
		this.altitude = altitude;
		this.heading = heading;
		this.latitude = latitude;
		this.longitude = longitude;
		this.speed = speed;

		return this;
	}
}

export { GeolocationCoordinates };