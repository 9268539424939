class SceneLoader {
    constructor(vue) {
        this.vue = vue;
        window.SCENE_LOADER = this;
    }

    async LoadScene(sceneType, sceneIndex, amountOfTargetsAsStr) {
        let sceneData = {
            sceneType: sceneType,
            sceneIndex: Number(sceneIndex),
            amountOfTargets: Number(amountOfTargetsAsStr),
        };

        this.vue.currentScene = sceneData;
    }

    async ScanSurface(sceneIndex) {
        if (window.currentScene == undefined) return;
        if (window.currentScene == null) return;
        if (!window.currentScene.ScanSurface) return;

        window.currentScene.ScanSurface();
    }
}

export { SceneLoader };