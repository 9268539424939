<template>
    <div class="fs layer0" ref="mc"></div>
</template>

<script>
    import { MindArTarget } from "@/AR-Renderer/mind_ar";
    import * as AR_Renderer from "@/AR-Renderer/ar_renderer";


    export default {
        name: 'TargetScene',
        props: {
            sceneData: Object
        },
        data() {
            return {
            };
        },
        mounted() {
            this.Entry();
        },
        methods: {
            async Entry() {
                this.$parent.ShowLoadingIndicator(true);
                await this.$parent.SetCurrentScene(this);
                this.Launch();
            },

            async Launch() {
                await this.Setup();
                await this.Start();
                return;
            },

            async Setup() {
                return await import("mind-ar/dist/mindar-image-three.prod.js").then(async () => {
                    let mindAr = MindArTarget(`./Targets/targets_${this.sceneData.sceneIndex}.mind`, this.$refs.mc);
                    this.mindAr = mindAr;

                    for (var i = 0; i < this.sceneData.amountOfTargets; i++) {
                        mindAr.addAnchor(i);
                    }

                    let renderer = mindAr.renderer;
                    let scene = mindAr.scene;
                    let camera = mindAr.camera;

                    this.Start = async () => {
                        await mindAr.start();

                        this.$parent.ShowLoadingIndicator(false);
                        AR_Renderer.onSceneLoaded(this.sceneData.sceneIndex);

                        for (var i = 0; i < this.sceneData.amountOfTarget; i++) {
                            mindAr.anchors[i].group.visible = false;
                        }

                        renderer.setAnimationLoop(() => {
                            this.CheckTarget(mindAr);
                        });

                        return;
                    }

                    this.Stop = async () => {
                        await mindAr.stop();
                        renderer.setAnimationLoop(null);
                        return;
                    }
                    return;
                });
            },

            CheckTarget(mindAr) {
                let aspect = mindAr.container.offsetWidth / mindAr.container.offsetHeight;

                let cameraFOV = aspect < (4 / 3) ? mindAr.camera.fov : mindAr.camera.fov * (4 / 3) / aspect;

                for (var i = 0; i < this.sceneData.amountOfTargets; i++) {
                    let sceneAnchor = mindAr.anchors[i];
                    if (sceneAnchor.group.visible) {
                        return AR_Renderer.onTargetTracking(sceneAnchor.group.matrix.elements, cameraFOV, i);
                    }
                }

                return AR_Renderer.onTargetTracking(mindAr.camera.matrix.elements, cameraFOV, -1);
            }
        }
    }
</script>

<style scoped>
    @import '../css/styles.css';
</style>
