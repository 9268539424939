<template>
    <div class="fs layer0" ref="mc"></div>
    <div v-if="isUserActionRequired" id="startScreen"><button @click="Entry()">START AR</button></div>
</template>

<script>
    import * as AR_Renderer from "@/AR-Renderer/ar_renderer";

    export default {
        name: 'MarkerScene',
        props: {
            sceneData: Object
        },
        data() {
            return {
                isUserActionRequired: false,

                hitMatrixElements: null,
            };
        },
        mounted() {
            this.Entry();
        },
        methods: {
            async Entry() {
                this.$parent.ShowLoadingIndicator(true);
                await this.$parent.SetCurrentScene(this);
                this.Launch();
            },

            async Launch() {
                const canvas = document.createElement("canvas");
                this.$refs.mc.appendChild(canvas);
                const gl = canvas.getContext("webgl", { xrCompatible: true });

                try {
                    this.session = await navigator.xr.requestSession(
                        'immersive-ar',
                        {
                            optionalFeatures: ["dom-overlay"],
                            requiredFeatures: ["hit-test"],
                            domOverlay: {root: this.sceneData.unity}
                        }
                    );

                    this.session.updateRenderState({
                        // eslint-disable-next-line no-undef
                        baseLayer: new XRWebGLLayer(this.session, gl)
                    });

                    const referenceSpace = await this.session.requestReferenceSpace('local');
                    const viewerSpace = await this.session.requestReferenceSpace('viewer');
                    const hitTestSource = await this.session.requestHitTestSource({ space: viewerSpace });

                    const onXRFrame = (time, frame) => {
                        this.session.requestAnimationFrame(onXRFrame);
                        const pose = frame.getViewerPose(referenceSpace);
                        if (pose) {
                            const matrix = pose.views[0].transform.matrix;
                            this.SendCameraTransformTounity(matrix);
                        }
                        
                        const hitTestResults = frame.getHitTestResults(hitTestSource);
                        if (hitTestResults.length > 0) {
                            this.hitMatrixElements = hitTestResults[0].getPose(referenceSpace).transform.matrix;
                        }
                    }
                    this.session.requestAnimationFrame(onXRFrame);
                } catch (e) {
                    console.error (e);
                    this.$parent.ShowLoadingIndicator(false);
                    this.isUserActionRequired = true;
                }
            },

            SendCameraTransformTounity(matrix) {
                const hitPointElements = this.hitMatrixElements == null ?  
                    new Array(16).fill(0) : 
                    this.hitMatrixElements;
                AR_Renderer.onMarkerTracking(matrix, hitPointElements, 90);
                this.hitMatrixElements = null;
            }
        }
    }
</script>

<style scoped>
    @import '../css/styles.css';

    #startScreen {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        backdrop-filter: blur(10px);
        justify-content:flex-end;
        align-items: center;
        flex-direction: column;
        padding: 50px;
        display: flex;
        z-index: 1;
    }

        #startScreen button {
            padding: 10px;
            background-color: transparent;
            border-radius: 10px;
            font-size: 14px;
            color: darkgrey;
            font-weight: bold;
            border: 1px solid darkgrey;
        }

            #startScreen button:active {
                color: lightgrey;
                border: 2px solid lightgrey;
            }
</style>
