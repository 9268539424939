import { Matrix4x4 } from "@/AR-Renderer/structs/matrix4x4";
import { Vector3 } from "@/AR-Renderer/structs/vector3";
import { Quaternion } from "@/AR-Renderer/structs/quaternion";
import * as Bridge from "./bridge";

let UnityInstance;


export function Init(canvas, callback) {
    var buildUrl = `./Build`;

    //DEBUG
    const DEBUG_MODE = (process.env.NODE_ENV === "production" ? false : true);

    var loaderUrl;
    var config;

    if (DEBUG_MODE) {
        loaderUrl = buildUrl + "/Build/Build.loader.js";
        config = {
            dataUrl: buildUrl + "/Build/Build.data",
            frameworkUrl: buildUrl + "/Build/Build.framework.js",
            codeUrl: buildUrl + "/Build/Build.wasm",
            streamingAssetsUrl: "StreamingAssets",
            companyName: "{{{ COMPANY_NAME }}}",
            productName: "{{{ PRODUCT_NAME }}}",
            productVersion: "{{{ PRODUCT_VERSION }}}",
        };
    } else {
        loaderUrl = buildUrl + "/{{{LOADER_FILENAME}}}";
        config = {
            dataUrl: buildUrl + "/{{{DATA_FILENAME}}}",
            frameworkUrl: buildUrl + "/{{{FRAMEWORK_FILENAME}}}",
            codeUrl: buildUrl + "/{{{CODE_FILENAME}}}",
            streamingAssetsUrl: "StreamingAssets",
            companyName: "{{{ COMPANY_NAME }}}",
            productName: "{{{ PRODUCT_NAME }}}",
            productVersion: "{{{ PRODUCT_VERSION }}}",
        };
    }

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    var script = document.createElement("script");
    script.src = loaderUrl;
    script.onload = () => {
        // eslint-disable-next-line no-undef
        createUnityInstance(canvas, config)
            .then((unityInstance) => {
                UnityInstance = unityInstance;
                Bridge.Init_functions(unityInstance);
                callback(unityInstance);
            })
            .catch((message) => {
                //Handle error
            });
    };
    canvas.appendChild(script);
}

function DecomposeMatrix(matrixElements, callback) {
    const matrix = new Matrix4x4(matrixElements);

    const position = new Vector3();
    const rotation = new Quaternion();
    const scale = new Vector3();

    matrix.decompose(position, rotation, scale);
    return {
        position: position,
        rotation: rotation,
        scale: scale
    }
;
}


//messages
export function onFaceTracking(sceneMatrixElements, cameraFOV, faceMeshData, status) {
    const sceneMatrix = DecomposeMatrix(sceneMatrixElements);

    const position = sceneMatrix.position;
    const rotation = sceneMatrix.rotation;
    const scale = sceneMatrix.scale;

    Bridge.OnFaceTracking(UnityInstance, position, rotation, scale, cameraFOV, faceMeshData, status);
}

export function onTargetTracking(sceneMatrixElements, cameraFOV, targetIndex) {
    const sceneMatrix = DecomposeMatrix(sceneMatrixElements);

    const position = sceneMatrix.position;
    const rotation = sceneMatrix.rotation;
    const scale = sceneMatrix.scale;

    Bridge.OnTargetTracking(UnityInstance, position, rotation, scale, cameraFOV, targetIndex);
}

export function onMarkerTracking(sceneMatrixElements, hitPointMatrixElements, cameraFOV) {
    const sceneMatrix = DecomposeMatrix(sceneMatrixElements);
    const hitPointMatrix = DecomposeMatrix(hitPointMatrixElements);

    const cameraPosition = sceneMatrix.position;
    const cameraRotation = sceneMatrix.rotation;
    const hitPointPosition = hitPointMatrix.position;

    const diff = Vector3.Direction(hitPointPosition, cameraPosition);

    const isZero = hitPointPosition.x == 0 && hitPointPosition.y == 0 && hitPointPosition.z == 0;
    const distanceToHitPoint = isZero? 0: diff.magnitude();

    Bridge.OnMarkerTracking(UnityInstance, cameraPosition, cameraRotation, distanceToHitPoint, cameraFOV);
}

export function onGeolocationTracking(sceneMatrixElements, cameraFOV, geoPos) {
    const sceneMatrix = DecomposeMatrix(sceneMatrixElements);

    const position = sceneMatrix.position;
    const rotation = sceneMatrix.rotation;

    Bridge.OnGeolocationTracking(UnityInstance, position, rotation, cameraFOV, geoPos);
}

export function onScanningFinished() {
    Bridge.OnScanningFinished(UnityInstance);
}

export function onSceneLoaded(sceneIndex) {
    Bridge.OnSceneLoaded(UnityInstance, sceneIndex);
}