import { FaceTransform, TargetTransform } from '@/AR-Renderer/classes/models';

let c_vtt, c_vft, c_vmt, c_vgt, c_vsf, c_vsl;
let Module

export function Init_functions(unityInstance) {
    Module = unityInstance.Module;
    
    c_vtt = unityInstance.Module.cwrap('call_cb_TargetTransform', null, ['number', 'number', 'number', 'number', 'number']);        //Traget transform  (V3, Q, V3, float, int)
    c_vft = unityInstance.Module.cwrap('call_cb_FaceTransform', null, ['number', 'number', 'number', 'number', 'string', 'number']);//Face transform    (V3, Q, V3, float, string, int)
    c_vmt = unityInstance.Module.cwrap('call_cb_MarkerTransform', null, ['number', 'number', 'number', 'number']);                  //Marker transform  (V3, Q, float)
    c_vgt = unityInstance.Module.cwrap('call_cb_GeolocationTransform', null, ['number', 'number', 'number', 'number']);             //Geolocation transform  (V3, Q, float, Geolocation struct)
    c_vsf = unityInstance.Module.cwrap('call_cb_ScanningFinished', null, []);                                                       //Scanning finished ()
    c_vsl = unityInstance.Module.cwrap('call_cb_SceneLoaded', null, ['number']);                                                    //Scene loaded      (float)
}

export function OnFaceTracking(unityInstance, position, rotation, scale, cameraFOV, faceMeshData, status) {
    let ft = new FaceTransform(position, rotation, scale, cameraFOV, faceMeshData);

    if (ft.isValid()) {
        if (unityInstance) {
            var dataPtr = Module._malloc(40);                   //12 (Vector3) + 16 (Quaternion) + 12 (Vector3)
            var fDataPtr = dataPtr >> 2;
            //Position  Vector3 (float, float, float)           = 12 bytes (3*sizeof(float))
            Module.HEAPF32[fDataPtr] = ft.position.x;
            Module.HEAPF32[fDataPtr + 1] = ft.position.y;
            Module.HEAPF32[fDataPtr + 2] = ft.position.z;
            //Rotation Quaternion (float, float, float, float)  = 16 bytes (4*sizeof(float))
            Module.HEAPF32[fDataPtr + 3] = ft.rotation.x;
            Module.HEAPF32[fDataPtr + 4] = ft.rotation.y;
            Module.HEAPF32[fDataPtr + 5] = ft.rotation.z;
            Module.HEAPF32[fDataPtr + 6] = ft.rotation.w;
            //Scale Vector3 (float, float, float)               = 12 bytes (3*sizeof(float))
            Module.HEAPF32[fDataPtr + 7] = ft.scale.x;
            Module.HEAPF32[fDataPtr + 8] = ft.scale.y;
            Module.HEAPF32[fDataPtr + 9] = ft.scale.z;

            c_vft(dataPtr, dataPtr + 12, dataPtr + 28, ft.cameraFOV, ft.faceMeshData, status);
            //Test();
            Module._free(dataPtr);
        }
    } else {
        console.error('Face transform is invalid!');
    }
}

export function OnTargetTracking(unityInstance, position, rotation, scale, cameraFOV, targetIndex) {
    let tt = new TargetTransform(position, rotation, scale, cameraFOV, targetIndex);

    if (tt.isValid()) {
        if (unityInstance) {
            var dataPtr = Module._malloc(40);                   //12 (Vector3) + 16 (Quaternion) + 12 (Vector3)
            var fDataPtr = dataPtr >> 2;
            //Position  Vector3 (float, float, float)           = 12 bytes (3*sizeof(float))
            Module.HEAPF32[fDataPtr] = tt.position.x;
            Module.HEAPF32[fDataPtr + 1] = tt.position.y;
            Module.HEAPF32[fDataPtr + 2] = tt.position.z;
            //Rotation Quaternion (float, float, float, float)  = 16 bytes (4*sizeof(float))
            Module.HEAPF32[fDataPtr + 3] = tt.rotation.x;
            Module.HEAPF32[fDataPtr + 4] = tt.rotation.y;
            Module.HEAPF32[fDataPtr + 5] = tt.rotation.z;
            Module.HEAPF32[fDataPtr + 6] = tt.rotation.w;
            //Scale Vector3 (float, float, float)               = 12 bytes (3*sizeof(float))
            Module.HEAPF32[fDataPtr + 7] = tt.scale.x;
            Module.HEAPF32[fDataPtr + 8] = tt.scale.y;
            Module.HEAPF32[fDataPtr + 9] = tt.scale.z;

            c_vtt(dataPtr, dataPtr + 12, dataPtr + 28, tt.cameraFOV, targetIndex);

            Module._free(dataPtr);
        }
    } else {
        console.error('Target transform is invalid!');
    }
}

export function OnMarkerTracking(unityInstance, cameraPosition, cameraRotation, distanceToHitPoint, cameraFOV) {
    if (unityInstance) {
        var dataPtr = Module._malloc(28);                   //12 (Vector3) + 16 (Quaternion) + 12 (Vector3)
        var fDataPtr = dataPtr >> 2;
        //Position  Vector3 (float, float, float)           = 12 bytes (3*sizeof(float))
        Module.HEAPF32[fDataPtr] = cameraPosition.x;
        Module.HEAPF32[fDataPtr + 1] = cameraPosition.y;
        Module.HEAPF32[fDataPtr + 2] = cameraPosition.z;
        //Rotation Quaternion (float, float, float, float)  = 16 bytes (4*sizeof(float))
        Module.HEAPF32[fDataPtr + 3] = cameraRotation.x;
        Module.HEAPF32[fDataPtr + 4] = cameraRotation.y;
        Module.HEAPF32[fDataPtr + 5] = cameraRotation.z;
        Module.HEAPF32[fDataPtr + 6] = cameraRotation.w;

        c_vmt(dataPtr, dataPtr + 12, distanceToHitPoint, cameraFOV);
        
		Module._free(dataPtr);
    }
}

export function OnGeolocationTracking(unityInstance, position, rotation, cameraFOV, geoPos) {
    if (unityInstance) {
        var dataPtr = Module._malloc(52);                   //12 (Vector3) + 16 (Quaternion) + 24(GeopositionCoordinates)
        var fDataPtr = dataPtr >> 2;
        //Position  Vector3 (float, float, float)           = 12 bytes (3*sizeof(float))
        Module.HEAPF32[fDataPtr] = position.x;
        Module.HEAPF32[fDataPtr + 1] = position.y;
        Module.HEAPF32[fDataPtr + 2] = position.z;
        //Rotation Quaternion (float, float, float, float)  = 16 bytes (4*sizeof(float))
        Module.HEAPF32[fDataPtr + 3] = rotation.x;
        Module.HEAPF32[fDataPtr + 4] = rotation.y;
        Module.HEAPF32[fDataPtr + 5] = rotation.z;
        Module.HEAPF32[fDataPtr + 6] = rotation.w;
        //Geolocation coordinates   (float, float, float, float, float, float) = 24 bytes (6*sizeof(float))
        Module.HEAPF32[fDataPtr + 7] = geoPos.accuracy;
        Module.HEAPF32[fDataPtr + 8] = geoPos.altitude;
        Module.HEAPF32[fDataPtr + 9] = geoPos.heading;
        Module.HEAPF32[fDataPtr + 10] = geoPos.latitude;
        Module.HEAPF32[fDataPtr + 11] = geoPos.longitude;
        Module.HEAPF32[fDataPtr + 12] = geoPos.speed;

        c_vgt(dataPtr, dataPtr + 12, cameraFOV, dataPtr + 28);

        Module._free(dataPtr);
    }
}

export function OnScanningFinished(unityInstance) {
    if (unityInstance) {
        c_vsf();
    }
}

export function OnSceneLoaded(unityInstance, sceneIndex) {
    if (unityInstance) {
        c_vsl(sceneIndex);
    }
}
