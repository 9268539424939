class Vector3 {

	constructor(x = 0, y = 0, z = 0) {
		this.x = x;
		this.y = y;
		this.z = z;
	}

	set(x, y, z) {
		this.x = x;
		this.y = y;
		this.z = z;

		return this;
	}

	magnitude() {
		return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
	}

	static Direction(a, b) {
		const x = a.x - b.x;
		const y = a.y - b.y;
		const z = a.z - b.z;

		return new Vector3(x, y, z);
	}
}

export { Vector3 };