class Quaternion {

	constructor(x = 0, y = 0, z = 0, w = 1) {
		this.x = x;
		this.y = y;
		this.z = z;
		this.w = w;
	}

	setFromRotationMatrix(matrix) {
		const te = matrix.elements;

		const m11 = te[0],
			m12 = te[4],
			m13 = te[8],
			m21 = te[1],
			m22 = te[5],
			m23 = te[9],
			m31 = te[2],
			m32 = te[6],
			m33 = te[10];

		const trace = m11 + m22 + m33;

		if (trace > 0) {
			const s = 0.5 / Math.sqrt(trace + 1.0);

			this.x = (m32 - m23) * s;
			this.y = (m21 - m12) * s;
			this.z = (m13 - m31) * s;
			this.w = 0.25 / s;

		} else if (m11 > m22 && m11 > m33) {
			const s = 2.0 * Math.sqrt(1.0 + m11 - m22 - m33);

			this.x = 0.25 * s;
			this.y = (m13 + m31) / s;
			this.z = (m12 + m21) / s;
			this.w = (m32 - m23) / s;

		} else if (m22 > m33) {
			const s = 2.0 * Math.sqrt(1.0 + m22 - m11 - m33);

			this.x = (m12 + m21) / s;
			this.y = (m23 + m32) / s;
			this.z = 0.25 * s;
			this.w = (m13 - m31) / s;

		} else {
			const s = 2.0 * Math.sqrt(1.0 + m33 - m11 - m22);

			this.x = (m13 + m31) / s;
			this.y = 0.25 * s;
			this.z = (m23 + m32) / s;
			this.w = (m21 - m12) / s;
		}
		
		//Convert rotation to Unity system;
		this.x *= -1;
		//this.y *= -1;
		//this.z *= -1;


		return this;
	}
}

export { Quaternion };