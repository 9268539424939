const PHOTO_WIDTH = 512;        //Bigger value = better surface recognition quality but longer loading time.

async function CreateCameraVideo(camera) {
    let video = document.createElement('video');

    video.setAttribute('autoplay', '');
    video.setAttribute('muted', '');
    video.setAttribute('playsinline', '');
    video.setAttribute('ref', 'video');
    video.style.position = 'absolute';
    video.style.top = '0px';
    video.style.left = '0px';
    video.style.zIndex = '-2';

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        return;
    }

    return await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
            facingMode: camera,
        }
    }).then((stream) => {
        video.addEventListener('loadedmetadata', () => {
            video.setAttribute('width', video.videoWidth + "px");
            video.setAttribute('height', video.videoHeight + "px");
            Resize(video);
            window.addEventListener('resize', () => {
                Resize(video);
            });
        });
        video.srcObject = stream;
        return video;
    }).catch((err) => {
        throw err;
    });
}

function TakePhoto(video) {
    let canvas = document.createElement("canvas");
    canvas.style.display = "none";
    canvas.width = PHOTO_WIDTH;
    canvas.height = video.videoHeight / video.videoWidth * canvas.width;

    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    let photo = canvas.toDataURL('image/jpeg');
    return photo;
}

function Resize(video) {
    let container = video?.parentElement;

    if (!video || !container) return;

    let vw, vh;
    const videoRatio = video.videoWidth / video.videoHeight;

    const containerRatio = container.clientWidth / container.clientHeight;
    if (videoRatio > containerRatio) {
        vh = container.clientHeight;
        vw = vh * videoRatio;
    } else {
        vw = container.clientWidth;
        vh = vw / videoRatio;
    }

    video.style.top = (-(vh - container.clientHeight) / 2) + "px";
    video.style.left = (-(vw - container.clientWidth) / 2) + "px";
    video.style.width = vw + "px";
    video.style.height = vh + "px";
}

function StopVideo(video) {
    if (video && video != null) {
        const tracks = video.srcObject.getTracks();
        tracks.forEach(function (track) {
            track.stop();
        });
        video.remove();
    }
}

module.exports = { CreateCameraVideo, TakePhoto, StopVideo };