//Image and Surface
const SCANNING_OVERLAY = "#scanningOverlay";
const LOADING_OVERLAY = "#loadingOverlay";
const WARM_UP_TOLERANCE = 2;
const MISS_TO_TOLERANCE = 2;
const FILTER_MIN_CF = 0.001;
const FILTER_BETA = 1000;
const MAX_TRACK = 30;


function MindArTarget(target, container) {
    return new window.MINDAR.IMAGE.MindARThree({
        container: container,
        imageTargetSrc: target,

        uiScanning: SCANNING_OVERLAY,
        uiLoading: LOADING_OVERLAY,

        warmupTolerance: WARM_UP_TOLERANCE,
        missTolerance: MISS_TO_TOLERANCE,
        filterMinCF: FILTER_MIN_CF,
        filterBeta: FILTER_BETA,
        maxTrack: MAX_TRACK
    });
}

function MindArFace(container) {
    return new window.MINDAR.FACE.MindARThree({
        container: container,

        uiScanning: SCANNING_OVERLAY,
        uiLoading: LOADING_OVERLAY,
    });
}

module.exports = {
    MindArTarget,
    MindArFace
}
