<template>
    <div class="fs layer0 debug" ref="mc"></div>
</template>

<script>
    import { MindArFace } from "@/AR-Renderer/mind_ar";
    import * as AR_Renderer from "@/AR-Renderer/ar_renderer";

    export default {
        name: 'FaceScene',
        props: {
            sceneData: Object
        },
        data() {
            return {
            };
        },
        mounted() {
            this.Entry();
        },
        methods: {
            async Entry() {
                this.$parent.ShowLoadingIndicator(true);
                await this.$parent.SetCurrentScene(this);
                this.Launch();
            },

            async Launch() {
                await this.Setup();
                await this.Start();
                return;
            },

            async Setup() {
                return await import("mind-ar/dist/mindar-face-three.prod.js").then(async () => {
                    let mindAr = MindArFace(this.$refs.mc);
                    this.mindAr = mindAr;

                    let faceMesh = mindAr.addFaceMesh();

                    mindAr.addAnchor(0);

                    let renderer = mindAr.renderer;
                    let scene = mindAr.scene;
                    let camera = mindAr.camera;

                    this.Start = async () => {
                        await mindAr.start();

                        this.$parent.ShowLoadingIndicator(false);
                        AR_Renderer.onSceneLoaded(this.sceneData.sceneIndex);

                        renderer.setAnimationLoop(() => {
                            renderer.render(scene, camera);
                            this.CheckTarget(mindAr, faceMesh.geometry.positions);
                        });

                        return;
                    }
                    this.Stop = async () => {
                        await mindAr.stop();
                        renderer.setAnimationLoop(null);
                        return;
                    }

                    return;
                });
            },

            CheckTarget(mindAr, faceMesh) {
                let aspect = mindAr.container.offsetWidth / mindAr.container.offsetHeight;

                let cameraFOV = aspect < (4 / 3) ? mindAr.camera.fov : mindAr.camera.fov * (4 / 3) / aspect;

                let sceneAnchor = mindAr.anchors[0];

                let status = 0;

                if (sceneAnchor.group.visible) {
                    status = 1;
                }
                let matrixElements = sceneAnchor.group.matrix.elements;

                AR_Renderer.onFaceTracking(matrixElements, cameraFOV, faceMesh, status);
            }
        }
    }
</script>

<style scoped>
    @import '../css/styles.css';

    .debug {
        transform: scale(-1, 1);
        background-color: transparent;
    }
</style>
