<template>
    <div id="main" ref="main">
        <button v-if="fullscreenSupported"
                @click="SetFullscreen(isFullscreen? false: true)"
                ref="fullscreenBtn"
                id="fullscreenBtn">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-fullscreen" viewBox="0 0 16 16">
                <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
            </svg>
        </button>

        <canvas class="fs layer1"
                id="unity-canvas"
                ref="uc">
        </canvas>
        <component :is="GetSceneComponent()"
                   :sceneData="currentScene"
                   :key="currentScene.sceneIndex">
        </component>


        <div v-if="ShowLoading"
             class="fs layer2"
             v-html="loadingScreens['loading']">
        </div>

        <div v-if="ShowScanning"
             class="fs layer2"
             v-html="loadingScreens['scanning']">
        </div>

        <div v-if="ShowSplash"
             class="fs layer2"
             v-html="loadingScreens['splash']">
        </div>

        <div v-if="ShowOrientation"
             class="fs layer2"
             v-html="loadingScreens['orientation']?loadingScreens['orientation']:loadingScreens['loading']">
        </div>
    </div>
</template>

<script>
    import * as AR_Renderer from "@/AR-Renderer/ar_renderer";
    import { SceneLoader } from "@/AR-Renderer/scene_loader";

    import FaceScene from "@/views/FaceScene.vue"
    import TargetScene from "@/views/TargetScene.vue"
    import MarkerScene from "@/views/MarkerScene.vue"
    import GeolocationScene from "@/views/GeolocationScene.vue"
    import SurfaceScene from "@/views/SurfaceScene.vue"

    export default {
        name: 'App',
        components: {
            FaceScene,
            TargetScene,
            MarkerScene,
            SurfaceScene
        },
        data() {
            return {
                fullscreenSupported: true,
                isFullscreen: false,
                sceneLoader: null,
                ShowLoading: false,
                ShowScanning: false,
                ShowSplash: true,
                ShowOrientation: false,
                loadingScreens: {},
                currentScene: {},
            };
        },
        mounted() {
            this.CheckFullscreenSupport();
            this.LoadHTMLs();
            this.Initialization();
            window.StopCurrentScene = this.StopCurrentScene;
            window.SetCurrentScene = this.SetCurrentScene;
            window.timeout = this.timeout;
        },
        methods: {
            CheckFullscreenSupport() {
                if (/iPhone/i.test(navigator.userAgent)) {
                    this.fullscreenSupported = false;
                }
            },
            async timeout(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            async SetCurrentScene(scene) {
                this.StopCurrentScene();
                window.currentScene = scene;
                return;
            },
            async StopCurrentScene() {
                if (window.currentScene && window.currentScene != null) {
                    if (window.currentScene.Stop) {
                        await window.currentScene.Stop();
                    }
                }
            },
            GetSceneComponent() {
                this.currentScene.unity = this.$refs.uc;
                switch (this.currentScene.sceneType) {
                    case "face":
                        return FaceScene;
                    case "image":
                        return TargetScene;
                    case "marker":
                        return MarkerScene;
                    case "geolocation":
                        return GeolocationScene;
                    case "surface":
                        return SurfaceScene;
                    default:
                        this.StopCurrentScene();
                        return null;
                }
            },
            LoadHTMLs() {
                const screens = [
                    "loading",
                    "scanning",
                    "splash",
                    "orientation"
                ]
                const vue = this;

                for (var i = 0; i < screens.length; i++) {
                    const index = i;
                    var rawFile = new XMLHttpRequest();
                    rawFile.open("GET", `${screens[index]}.html`, false);
                    rawFile.onreadystatechange = function () {
                        if (rawFile.readyState === 4) {
                            if (rawFile.status === 200 || rawFile.status == 0) {
                                var allText = rawFile.responseText;
                                vue.loadingScreens[screens[index]] = allText;
                            }
                        }
                    }
                    rawFile.send(null);
                }

                console.warn(this.loadingScreens)
            },
            async ReloadScene() {
                if (window.currentScene && window.currentScene != null) {
                    if (window.currentScene.Entry) {
                        window.currentScene.Entry();
                    }
                }
            },
            async Initialization() {
                let unityContainer = this.$refs.uc;
                let arContainer = this.$refs.arContainer;

                this.sceneLoader = new SceneLoader(this);

                let vue = this;
                window.addEventListener("orientationchange", function () {
                    vue.ReloadScene();
                }, false);

                AR_Renderer.Init(unityContainer, () => {
                    this.ShowSplashScreen(false);
                });
            },

            SetFullscreen(state) {
                if (state) {
                    const lockScreen = () => {
                        this.isFullscreen = true;
                        if (window.matchMedia("(orientation: portrait)").matches) {
                            screen.orientation.lock("portrait-primary");
                        }
                        if (window.matchMedia("(orientation: landscape)").matches) {
                            screen.orientation.lock("landscape-primary");
                        }
                    }


                    if (this.$refs.main.requestFullscreen) {
                        this.$refs.main.requestFullscreen()
                            .then(lockScreen)
                            .catch((err) => {
                                console.error(err);
                            });
                    }

                    if (this.$refs.main.webkitRequestFullScreen) {
                        this.$refs.main.webkitRequestFullScreen()
                            .then(lockScreen)
                            .catch((err) => {
                                console.error(err);
                            });
                    }

                    if (this.$refs.main.mozRequestFullScreen) {
                        this.$refs.main.mozRequestFullScreen()
                            .then(lockScreen)
                            .catch((err) => {
                                console.error(err);
                            });
                    }

                } else {
                    document.exitFullscreen()
                        .then(() => {
                            this.isFullscreen = false;
                        })
                        .catch((err) => {
                            console.error(err);
                        })
                }
            },

            ShowLoadingIndicator(state) {
                this.ShowLoading = state;
            },
            ShowScanningIndicator(state) {
                this.ShowScanning = state;
            },
            ShowSplashScreen(state) {
                this.ShowSplash = state;
            },
            ShowOrientationScreen(state) {
                this.ShowOrientation = state;
            }
        }
    }
</script>

<style>
    @import '../css/styles.css';
</style>
