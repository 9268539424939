<template>
    <div class="fs layer0" ref="mc"></div>
    <div v-if="isUserActionRequired" id="startScreen"><button @click="Entry()">START AR</button></div>
</template>

<script>
    import { GeolocationCoordinates } from "@/AR-Renderer/structs/geolocation_coordinates";
    import * as AR_Renderer from "@/AR-Renderer/ar_renderer";

    export default {
        name: 'GeolocationScene',
        props: {
            sceneData: Object
        },
        data() {
            return {
                isUserActionRequired: false,

                geoCoords: {},
            };
        },
        mounted() {
            this.Entry();
        },
        methods: {
            async Entry() {
                this.$parent.ShowLoadingIndicator(true);
                await this.$parent.SetCurrentScene(this);
                this.Launch();
            },

            async Launch() {
                const canvas = document.createElement("canvas");
                this.$refs.mc.appendChild(canvas);
                const gl = canvas.getContext("webgl", { xrCompatible: true });

                try {
                    this.session = await navigator.xr.requestSession(
                        'immersive-ar',
                        {
                            optionalFeatures: ["dom-overlay"],
                            domOverlay: {root: this.sceneData.unity}
                        }
                    );

                    this.session.updateRenderState({
                        // eslint-disable-next-line no-undef
                        baseLayer: new XRWebGLLayer(this.session, gl)
                    });

                    const referenceSpace = await this.session.requestReferenceSpace('local');

                    this.AddOrientationEvents();
                    const options = {
                        enableHighAccuracy: true
                    }
                    navigator.geolocation.watchPosition((geoPos) => { this.geoCoords = geoPos.coords; }, undefined, options);

                    const onXRFrame = (time, frame) => {
                        this.session.requestAnimationFrame(onXRFrame);
                        const pose = frame.getViewerPose(referenceSpace);
                        if (pose) {
                            const matrix = pose.views[0].transform.matrix;
                            this.SendCameraTransformTounity(matrix);
                        }
                    }
                    this.session.requestAnimationFrame(onXRFrame);
                } catch (e) {
                    console.error(e);
                    this.$parent.ShowLoadingIndicator(false);
                    this.isUserActionRequired = true;
                }
            },

            AddOrientationEvents(){
                if (window.DeviceOrientationEvent) {
                    switch (this.CheckDevice()) {
                        case "iOS":
                            addEventListener('deviceorientation', this.OrientationHandler, true);
                            break;
                        case "Android":
                            addEventListener('deviceorientationabsolute', this.OrientationHandler, true);
                            break;
                        default:
                            alert("Device orientation is not supported");
                            return;
                    }
                } else{
                    alert ("Device orientation is not supported");
                }
            },

            CheckDevice() {
                var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                if (/android/i.test(userAgent)) {
                    return "Android";
                }
                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    return "iOS";
                }
                return "unknown";
            },

            OrientationHandler(e) {
                if (e.webkitCompassHeading) {
                    this.northHeading = this.compassHeading(e.webkitCompassHeading, e.beta, e.gamma);
                } else {
                    this.northHeading = this.compassHeading(e.alpha, e.beta, e.gamma);
                }
            },

            SendCameraTransformTounity(matrix) {
                let geoPos = new GeolocationCoordinates();

                if (this.geoCoords) {
                    geoPos = new GeolocationCoordinates(
                        this.geoCoords.accuracy,
                        this.geoCoords.altitude,
                        this.northHeading,
                        this.geoCoords.latitude,
                        this.geoCoords.longitude,
                        this.geoCoords.speed
                    )
                }

                AR_Renderer.onGeolocationTracking(matrix, 50, geoPos);
                console.warn(geoPos);
            },

            compassHeading (alpha, beta, gamma) {
                const alphaRad = alpha * (Math.PI / 180);
                const betaRad = beta * (Math.PI / 180);
                const gammaRad = gamma * (Math.PI / 180);

                const cA = Math.cos(alphaRad);
                const sA = Math.sin(alphaRad);
                const cB = Math.cos(betaRad);
                const sB = Math.sin(betaRad);
                const cG = Math.cos(gammaRad);
                const sG = Math.sin(gammaRad);

                const rA = - cA * sG - sA * sB * cG;
                const rB = - sA * sG + cA * sB * cG;
                const rC = - cB * cG;

                let compassHeading = Math.atan(rA / rB);

                if (rB < 0) {
                    compassHeading += Math.PI;
                } else if (rA < 0) {
                    compassHeading += 2 * Math.PI;
                }

                compassHeading *= 180 / Math.PI;
                return compassHeading;
            }
        }
    }
</script>

<style scoped>
    @import '../css/styles.css';

    #startScreen {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        backdrop-filter: blur(10px);
        justify-content:flex-end;
        align-items: center;
        flex-direction: column;
        padding: 50px;
        display: flex;
        z-index: 1;
    }

        #startScreen button {
            padding: 10px;
            background-color: transparent;
            border-radius: 10px;
            font-size: 14px;
            color: darkgrey;
            font-weight: bold;
            border: 1px solid darkgrey;
        }

            #startScreen button:active {
                color: lightgrey;
                border: 2px solid lightgrey;
            }
</style>
