import "mind-ar/dist/mindar-image.prod.js";

const compiler = new window.MINDAR.IMAGE.Compiler();

export async function GenerateTarget(files) {
    const images = [];
    for (let i = 0; i < files.length; i++) {
        let img = await loadImage(files[i])
        images.push(img);
    }

    const dataList = await compiler.compileImageTargets(images,(progress) => {
            console.log(progress);
        }
    );

    const exportedBuffer = await compiler.exportData();
    let link = BufferToBlobLink(exportedBuffer);
    return link;
}

async function loadImage  (file) {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = file;
    });
}

function BufferToBlobLink(buffer) {
    var blob = new Blob([buffer]);
    var aLink = window.document.createElement('a');
    aLink.download = 'targets.mind';
    aLink.href = window.URL.createObjectURL(blob);
    return (aLink.href)
}