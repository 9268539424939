import { Vector3 } from '@/AR-Renderer/structs/vector3.js';

class Matrix4x4 {

	constructor(arr = [	1, 0, 0, 0,
						0, 1, 0, 0,
						0, 0, 1, 0,
						0, 0, 0, 1])
	{
		this.elements = arr;
	}

	clone(matrix) {
		const te = this.elements;
		const me = matrix.elements;

		te[0] = me[0]; te[1] = me[1]; te[2] = me[2]; te[3] = me[3];
		te[4] = me[4]; te[5] = me[5]; te[6] = me[6]; te[7] = me[7];
		te[8] = me[8]; te[9] = me[9]; te[10] = me[10]; te[11] = me[11];
		te[12] = me[12]; te[13] = me[13]; te[14] = me[14]; te[15] = me[15];

		return this;
	}

	decompose(position, rotation, scale) {
		const elems = this.elements;

		let scaleX = new Vector3(elems[0], elems[1], elems[2]).magnitude();
		const scaleY = new Vector3(elems[4], elems[5], elems[6]).magnitude();
		const scaleZ = new Vector3(elems[8], elems[9], elems[10]).magnitude();

		const det = this.determinant();

		if (det < 0) {
			scaleX = - scaleX;
		}

		const scaleX_inv = 1 / scaleX;
		const scaleY_inv = 1 / scaleY;
		const scaleZ_inv = 1 / scaleZ;

		const tm = new Matrix4x4();
		tm.clone(this);

		tm.elements[0] *= scaleX_inv;
		tm.elements[1] *= scaleX_inv;
		tm.elements[2] *= scaleX_inv;

		tm.elements[4] *= scaleY_inv;
		tm.elements[5] *= scaleY_inv;
		tm.elements[6] *= scaleY_inv;

		tm.elements[8] *= scaleZ_inv;
		tm.elements[9] *= scaleZ_inv;
		tm.elements[10] *= scaleZ_inv;

		position.set(elems[12], elems[14], elems[13]);
		scale.set(scaleX, scaleY, scaleZ);
		rotation.setFromRotationMatrix(tm);

		return this;
	}

	determinant() {
		const te = this.elements;

		const n11 = te[0], n12 = te[4], n13 = te[8], n14 = te[12];
		const n21 = te[1], n22 = te[5], n23 = te[9], n24 = te[13];
		const n31 = te[2], n32 = te[6], n33 = te[10], n34 = te[14];
		const n41 = te[3], n42 = te[7], n43 = te[11], n44 = te[15];

		return (
			n41 * (
				+ n14 * n23 * n32
				- n13 * n24 * n32
				- n14 * n22 * n33
				+ n12 * n24 * n33
				+ n13 * n22 * n34
				- n12 * n23 * n34
			) +
			n42 * (
				+ n11 * n23 * n34
				- n11 * n24 * n33
				+ n14 * n21 * n33
				- n13 * n21 * n34
				+ n13 * n24 * n31
				- n14 * n23 * n31
			) +
			n43 * (
				+ n11 * n24 * n32
				- n11 * n22 * n34
				- n14 * n21 * n32
				+ n12 * n21 * n34
				+ n14 * n22 * n31
				- n12 * n24 * n31
			) +
			n44 * (
				- n13 * n22 * n31
				- n11 * n23 * n32
				+ n11 * n22 * n33
				+ n13 * n21 * n32
				- n12 * n21 * n33
				+ n12 * n23 * n31
			)
		);
	}
}

export { Matrix4x4 };